import React from 'react';
import './TrendingCoins.css';

const TrendingCoins = () => {
  return (
    <section className="trending-coins">
      <h2>FIND TRENDING MEME COINS ON <span className="highlight">KABOOM</span></h2>
      <div className="content">
        <div className="column">
          <div className="text-item">
            <h3 className="red">BOOM TO BUY MEMECOINS</h3>
            <p>BOOM within seconds to buy meme coins like playing tap-to-earn games! No wallet signing for self-set limits! Ride the wave, study later.</p>
          </div>
          <div className="text-item">
            <h3 className="red">BET TO EARN</h3>
            <p>Bet on bullish or bearish views for meme coins & cryptos! Winners snag profits from the betting pool. Multi-crypto, multi-rounds, pure game fun!</p>
          </div>
          <div className="text-item">
            <h3 className="red">ALGORITHMIC INSIGHTS</h3>
            <p>Recommend trending meme coins with AI-powered machine learning algorithms and decentralized betting votes.</p>
          </div>
        </div>
        <div className="image-content">
          <img src="/screen.png" alt="Trending Meme Coins" className="trending-image" />
        </div>
        <div className="column">
          <div className="text-item">
            <h3 className="red">ONE ACCOUNT. MULTI-CHAINS</h3>
            <p>One account that allows users seamlessly trade assets natively across EVM and non-EVM chains.</p>
          </div>
          <div className="text-item">
            <h3 className="red">TRADE SAFE WITH MPC WALLET</h3>
            <p>Trade safe with MPC wallet powered by Custonomy. Deposit via on-chain transfers and credit card are both supported.</p>
          </div>
          <div className="text-item">
            <h3 className="red">MEME COMMUNITY</h3>
            <p>Boom to unlock the community forum where holders freely express their passion and views for the memecoins.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TrendingCoins;
