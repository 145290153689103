
import React from 'react';
import Header from './components/Header';
import MainSection from './components/MainSection';
import TrendingCoins from './components/TrendingCoins';
import TopChains from './components/TopChains';
import Roadmap from './components/Roadmap';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="content-wrapper">
        <Header />
        <TrendingCoins />
        <Roadmap />
        <Footer />
      </div>
    </div>
  );
}

export default App;
