import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="slogan">
        <div className="slogan-content">
          Shop Memes Anytime, Anywhere by One Click! &nbsp;&nbsp;&nbsp; Shop Memes Anytime, Anywhere by One Click! &nbsp;&nbsp;&nbsp; Shop Memes Anytime, Anywhere by One Click!
        </div>
      </div>
      <div className="background">
        <img src="/background.png" alt="Background" className="background-image" />
        <img src="/start1.png" alt="Stars 1" className="stars1" />
        <img src="/start2.png" alt="Stars 2" className="stars2" />
        <img src="/logo.png" alt="KaBoom Logo" className="logo" />
        <div className="rocket-frame">
          <img src="/rocket_frame.png" alt="Rocket Frame" className="rocket-frame-image" />
        </div>
        <div className="buttons">
            <a href="https://t.me/kaboom_meme" className="btn btn-community" target="_blank" rel="noopener noreferrer">JOIN COMMUNITY</a>
            <a href="https://t.me/kaboom_meme_bot" className="btn btn-launch" target="_blank" rel="noopener noreferrer">LAUNCH KABOOM</a>
        </div>
        <div className="rocketman">
          <img src="/rocketman.png" alt="Rocketman" className="rocketman-image" />
        </div>
        <img src="/cloud.png" alt="Cloud" className="cloud-image" />
        <h1>Shop and Bet Meme Coins Anytime, Anywhere by One Click!</h1>
      </div>
    </header>
  );
}

export default Header;
