import React from 'react';
import './Roadmap.css';

const Roadmap = () => {
  return (
    <section className="roadmap">
      <h2>$FUEL TOKEN <span className="highlight">ROADMAP</span></h2>
      <div className="content">
        <div className="roadmap-item">
          <h3 className="title">ASSEMBLING</h3>
          <p>Soft launch with telegram mini games</p>
          <p>NFT airdrop for token claiming</p>
          <p>Grand marketing campaign</p>
        </div>
        <div className="roadmap-item">
          <h3 className="title">FUELING</h3>
          <p>$FUEL token presale</p>
          <p>Incentive campaign for $FUEL holders</p>
          <p>$FUEL farming through trading</p>
          <p>List $FUEL on DEXes</p>
        </div>
        <div className="rocket">
          <img src="/rocket.png" alt="Rocket" className="rocket-image" />
        </div>
        <div className="roadmap-item">
          <h3 className="title">LIFTOFF</h3>
          <p>CEX listings</p>
          <p>HUGE partnerships</p>
          <p>Implement staking and burning mechanics</p>
        </div>
        <div className="roadmap-item">
          <h3 className="title">TO DA MOON</h3>
          <p>$FUEL mass adoption</p>
          <p>Massive airdrops for $FUEL holders</p>
          <p>Trading competition</p>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
