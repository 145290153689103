import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src="/footer_left.png" alt="Left Image" className="footer-image left-image" />
        <img src="/footer_background.png" alt="Background Image" className="footer-image background-image" />
        <img src="/footer_middle.png" alt="Middle Image" className="footer-image middle-image" />
        <img src="/footer_right.png" alt="Right Image" className="footer-image right-image" />
      </div>
      <div className="footer-buttons">
        <a href="https://x.com/kaboom_meme" className="button twitter" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://t.me/kaboom_meme" className="button telegram" target="_blank" rel="noopener noreferrer">Telegram</a>
        <a href="https://docs.kaboom.meme" className="button litepaper" target="_blank" rel="noopener noreferrer">Litepaper</a>
      </div>
      <div className="mobile-footer-background">
        <img src="/mobile_footer_background.png" alt="Mobile Footer Background" />
        <div className="mobile-footer-buttons">
          <a href="https://x.com/kaboom_meme" className="button twitter">TWITTER</a>
          <a href="https://t.me/kaboom_meme" className="button telegram">TELEGRAM</a>
          <a href="https://docs.kaboom.meme" className="button litepaper">LITEPAPER</a>
        </div>
      </div>
      
    </footer>
  );
}

export default Footer;
